<template>
  <div class="h-px w-full bg-gray-200" :class="customClass" />
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Separator',
  props: {
    customClass: {
      type: String,
      default: 'mb-4 mt-4',
    },
  },
})
</script>
