import {
  FULFILL_LINE_ITEMS,
  CANCEL_FULFILLMENT,
  DELETE_LINE_ITEM_CUSTOM_ATTRIBUTE_KEY,
  SET_CUSTOMER_BILLING_ADDRESS,
  SET_CUSTOMER_SHIPPING_ADDRESS,
  SET_TRACK_TO_FULFILLMENT,
  REFUND_ORDER,
  CANCEL_ORDER,
  RESTOCK_LINE_ITEMS,
  UPDATE_CUSTOMER,
  EXPORT_ORDERS,
} from './../graphql/mutation/order'
import {
  OrderFulfillmentInput,
  MutationFulfillLineItemsArgs,
  MutationCancelFulfillmentArgs,
  Maybe,
  MutationDeleteLineItemCustomAttributeKeyArgs,
  MutationSetCustomerBillingAddressArgs,
  MutationSetCustomerShippingAddressArgs,
  MutationUpdateCustomerArgs,
  Order,
  OrderBillingAddressInput,
  OrdersCount,
  OrderShippingAddressInput,
  MutationSetTrackToFulfillmentArgs,
  OrderTrackerInput,
  QueryGetCustomerOrdersCountArgs,
  QueryGetOrderArgs,
  MutationRefundOrderArgs,
  MutationCancelOrderArgs,
  RefundParamsInput,
  MutationRestockLineItemsArgs,
  RestockedLineItemInput,
  OrderCustomerBodyInput,
  MutationExportOrdersArgs,
} from './../../../shared/types/types'
import apolloClient from '@/services/apollo'
import { GET_CUSTOMER_ORDERS_COUNT, GET_ORDER } from '../graphql/query/order'

interface GetOrderResult {
  getOrder: Maybe<Order>
}

interface DeleteLineItemCustomAttributeKeyResult {
  deleteLineItemCustomAttributeKey: Maybe<Order>
}

interface GetCustomerOrdersCountResult {
  getCustomerOrdersCount: OrdersCount
}

interface SetCustomerBillingAddressResult {
  setCustomerBillingAddress: Maybe<Order>
}

interface SetCustomerShippingAddressResult {
  setCustomerShippingAddress: Maybe<Order>
}

interface UpdateCustomerResult {
  updateCustomer: Maybe<Order>
}

interface FulfillLineItemsResult {
  fulfillLineItems: Maybe<Order>
}

interface CancelFulfillmentResult {
  cancelFulfillment: Maybe<Order>
}

interface CancelOrderResult {
  cancelOrder: Maybe<Order>
}

interface RestockLineItemsResult {
  restockLineItems: Maybe<Order>
}

interface SetTrackToFulfillmentResult {
  setTrackToFulfillment: Maybe<Order>
}

interface RefundOrder {
  refundOrder: Maybe<Order>
}

interface ExportOrdersResult {
  exportOrders: Maybe<string>
}

/**
 * Get order
 *
 * @param orderId Order ID
 */
export const getOrder = async (orderId: string): Promise<Maybe<Order>> => {
  const { data } = await apolloClient.query<GetOrderResult, QueryGetOrderArgs>({
    query: GET_ORDER,
    variables: {
      orderId,
    },
    /* Caching by apolloClient is using 'id' for creating unique reference which is causing issues in fulfillments array,
    so temporarily we are disabling cache for this query. Need to work on the right fix: https://www.apollographql.com/docs/react/caching/cache-configuration/ */
    fetchPolicy: 'no-cache',
  })
  return data.getOrder
}

/**
 * Get customer orders count
 *
 * @param customerId Customer ID
 */
export const getCustomerOrdersCount = async (
  customerId: string
): Promise<OrdersCount> => {
  const { data } = await apolloClient.query<
    GetCustomerOrdersCountResult,
    QueryGetCustomerOrdersCountArgs
  >({
    query: GET_CUSTOMER_ORDERS_COUNT,
    variables: {
      customerId,
    },
  })

  return data.getCustomerOrdersCount
}

/**
 * Delete a product
 *
 * @param orderId Order ID
 * @param lineItemId Order ID
 * @param attributeKey Order ID
 */
export const deleteLineItemCustomAttributeKey = async (
  orderId: string,
  lineItemId: string,
  attributeKey: string
): Promise<Maybe<Order>> => {
  const { data } = await apolloClient.mutate<
    DeleteLineItemCustomAttributeKeyResult,
    MutationDeleteLineItemCustomAttributeKeyArgs
  >({
    mutation: DELETE_LINE_ITEM_CUSTOM_ATTRIBUTE_KEY,
    variables: {
      orderId,
      lineItemId,
      attributeKey,
    },
  })

  return data?.deleteLineItemCustomAttributeKey as Maybe<Order>
}

/**
 * Change customer's billing address in order
 *
 * @param orderId Order ID
 * @param billingAddress Billing address
 */
export const setCustomerBillingAddress = async (
  orderId: string,
  billingAddress: OrderBillingAddressInput
): Promise<Maybe<Order>> => {
  const { data } = await apolloClient.mutate<
    SetCustomerBillingAddressResult,
    MutationSetCustomerBillingAddressArgs
  >({
    mutation: SET_CUSTOMER_BILLING_ADDRESS,
    variables: {
      orderId,
      billingAddress,
    },
  })

  return data?.setCustomerBillingAddress as Maybe<Order>
}

/**
 * Change customer's shipping address in order
 *
 * @param orderId Order ID
 * @param shippingAddress Shipping address
 */
export const setCustomerShippingAddress = async (
  orderId: string,
  shippingAddress: OrderShippingAddressInput
): Promise<Maybe<Order>> => {
  const { data } = await apolloClient.mutate<
    SetCustomerShippingAddressResult,
    MutationSetCustomerShippingAddressArgs
  >({
    mutation: SET_CUSTOMER_SHIPPING_ADDRESS,
    variables: {
      orderId,
      shippingAddress,
    },
  })

  return data?.setCustomerShippingAddress as Maybe<Order>
}

/**
 * Update customer info in order
 *
 * @param orderId Order ID
 * @param customer Customer Object
 */
export const updateOrderCustomer = async (
  orderId: string,
  customer: OrderCustomerBodyInput
): Promise<Maybe<Order>> => {
  const { data } = await apolloClient.mutate<
    UpdateCustomerResult,
    MutationUpdateCustomerArgs
  >({
    mutation: UPDATE_CUSTOMER,
    variables: {
      orderId,
      customer,
    },
  })

  return data?.updateCustomer as Maybe<Order>
}

/**
 * Fulfill order's lineitems
 *
 * @param orderId Order ID
 * @param fulfillment OrderFulfillmentInput
 */
export const fulfillLineItems = async (
  orderId: string,
  fulfillment: OrderFulfillmentInput
): Promise<Maybe<Order>> => {
  const { data } = await apolloClient.mutate<
    FulfillLineItemsResult,
    MutationFulfillLineItemsArgs
  >({
    mutation: FULFILL_LINE_ITEMS,
    variables: {
      orderId,
      fulfillment,
    },
  })

  return data?.fulfillLineItems as Maybe<Order>
}

/**
 * Cancel order fulfillment
 *
 * @param orderId Order ID
 * @param fulfillmentId Fulfillment ID
 */
export const cancelFulfillment = async (
  orderId: string,
  fulfillmentId: string
): Promise<Maybe<Order>> => {
  const { data } = await apolloClient.mutate<
    CancelFulfillmentResult,
    MutationCancelFulfillmentArgs
  >({
    mutation: CANCEL_FULFILLMENT,
    variables: {
      orderId,
      fulfillmentId,
    },
  })

  return data?.cancelFulfillment as Maybe<Order>
}

/**
 * Set tracker to order fulfillment
 *
 * @param orderId Order ID
 * @param fulfillmentId Fulfillment ID
 * @param tracker OrderTrackerInput
 */
export const setTrackToFulfillment = async (
  orderId: string,
  fulfillmentId: string,
  tracker: OrderTrackerInput
): Promise<Maybe<Order>> => {
  const { data } = await apolloClient.mutate<
    SetTrackToFulfillmentResult,
    MutationSetTrackToFulfillmentArgs
  >({
    mutation: SET_TRACK_TO_FULFILLMENT,
    variables: {
      orderId,
      fulfillmentId,
      tracker,
    },
  })

  return data?.setTrackToFulfillment as Maybe<Order>
}
/**
 * Refund a product
 *
 * @param orderId Order ID
 * @param refund Refund amount
 */
export const refundOrder = async (
  orderId: string,
  refund: RefundParamsInput
): Promise<Maybe<Order>> => {
  const { data } = await apolloClient.mutate<
    RefundOrder,
    MutationRefundOrderArgs
  >({
    mutation: REFUND_ORDER,
    variables: {
      orderId,
      refund,
    },
  })
  return data?.refundOrder as Maybe<Order>
}

/**
 * Cancel order
 *
 * @param orderId Order ID
 * @param reason Cancelation reason
 */
export const cancelOrder = async (
  orderId: string,
  reason: string,
  notifyCustomer: boolean
): Promise<Maybe<Order>> => {
  const { data } = await apolloClient.mutate<
    CancelOrderResult,
    MutationCancelOrderArgs
  >({
    mutation: CANCEL_ORDER,
    variables: {
      orderId,
      reason,
      notifyCustomer,
    },
  })

  return data?.cancelOrder as Maybe<Order>
}

/**
 * Cancel order
 *
 * @param orderId Order ID
 * @param restocks LineItems to be restocked
 */
export const restockLineItems = async (
  orderId: string,
  restocks: RestockedLineItemInput[]
): Promise<Maybe<Order>> => {
  const { data } = await apolloClient.mutate<
    RestockLineItemsResult,
    MutationRestockLineItemsArgs
  >({
    mutation: RESTOCK_LINE_ITEMS,
    variables: {
      orderId,
      restocks,
    },
  })

  return data?.restockLineItems as Maybe<Order>
}

/**
 * Export orders
 *
 * @param orderId Order ID
 */
export const exportOrders = async (
  email: string,
  elasticSearchQueryString: string
): Promise<Maybe<string>> => {
  const { data } = await apolloClient.mutate<
    ExportOrdersResult,
    MutationExportOrdersArgs
  >({
    mutation: EXPORT_ORDERS,
    variables: {
      input: {
        elasticSearchQueryString,
        email,
      },
    },
  })

  return data?.exportOrders as Maybe<string>
}
