import gql from 'graphql-tag'

/**
 * Mutation to delete a order for the specified orderId
 */
export const DELETE_ORDER = gql`
  mutation deleteOrder($orderId: ID!) {
    deleteOrder(orderId: $orderId) {
      isDeleteSuccess
    }
  }
`
/**
 * Mutation to delete a custom attribute key on a line item
 */
export const DELETE_LINE_ITEM_CUSTOM_ATTRIBUTE_KEY = gql`
  mutation deleteLineItemCustomAttributeKey(
    $orderId: ID!
    $lineItemId: ID!
    $attributeKey: String!
  ) {
    deleteLineItemCustomAttributeKey(
      orderId: $orderId
      lineItemId: $lineItemId
      attributeKey: $attributeKey
    ) {
      orderId
      lineItems {
        lineItemId
        customAttributes
      }
    }
  }
`

/**
 * Mutation to set customer's billing address in order
 */
export const SET_CUSTOMER_BILLING_ADDRESS = gql`
  mutation setCustomerBillingAddress(
    $orderId: ID!
    $billingAddress: OrderBillingAddressInput!
  ) {
    setCustomerBillingAddress(
      orderId: $orderId
      billingAddress: $billingAddress
    ) {
      orderId
      customer {
        billingAddress {
          address1
          address2
          addressCode
          city
          company
          country
          countryCode
          division
          divisionCode
          firstName
          lastName
          latitude
          longitude
          phone
        }
      }
    }
  }
`

/**
 * Mutation to set customer's shipping address in order
 */
export const SET_CUSTOMER_SHIPPING_ADDRESS = gql`
  mutation setCustomerShippingAddress(
    $orderId: ID!
    $shippingAddress: OrderShippingAddressInput!
  ) {
    setCustomerShippingAddress(
      orderId: $orderId
      shippingAddress: $shippingAddress
    ) {
      orderId
      customer {
        shippingAddress {
          address1
          address2
          addressCode
          city
          company
          country
          countryCode
          division
          divisionCode
          firstName
          lastName
          latitude
          longitude
          phone
        }
      }
    }
  }
`

/**
 * Mutation to update customer details on the order
 */
export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($orderId: ID!, $customer: OrderCustomerBodyInput!) {
    updateCustomer(orderId: $orderId, customer: $customer) {
      orderId
      customer {
        email
      }
    }
  }
`

/**
 * Mutation to fulfill order's lineitems
 */
export const FULFILL_LINE_ITEMS = gql`
  mutation fulfillLineItems(
    $orderId: ID!
    $fulfillment: OrderFulfillmentInput!
  ) {
    fulfillLineItems(orderId: $orderId, fulfillment: $fulfillment) {
      orderId
    }
  }
`

/**
 * Mutation to cancel order fulfillment
 */
export const CANCEL_FULFILLMENT = gql`
  mutation cancelFulfillment($orderId: ID!, $fulfillmentId: ID!) {
    cancelFulfillment(orderId: $orderId, fulfillmentId: $fulfillmentId) {
      orderId
    }
  }
`

/**
 * Mutation to set tracker to order fulfillment
 */
export const SET_TRACK_TO_FULFILLMENT = gql`
  mutation setTrackToFulfillment(
    $orderId: ID!
    $fulfillmentId: ID!
    $tracker: OrderTrackerInput!
  ) {
    setTrackToFulfillment(
      orderId: $orderId
      fulfillmentId: $fulfillmentId
      tracker: $tracker
    ) {
      orderId
      fulfillments {
        id
        tracker {
          trackingNumber
          carrier
        }
      }
    }
  }
`
/**
 * Mutation to refund an Order
 */
export const REFUND_ORDER = gql`
  mutation refundOrder($orderId: ID!, $refund: RefundParamsInput!) {
    refundOrder(orderId: $orderId, refund: $refund) {
      orderId
    }
  }
`

/**
 * Mutation to cancel order
 */
export const CANCEL_ORDER = gql`
  mutation cancelOrder(
    $orderId: ID!
    $reason: String!
    $notifyCustomer: Boolean!
  ) {
    cancelOrder(
      orderId: $orderId
      reason: $reason
      notifyCustomer: $notifyCustomer
    ) {
      orderId
    }
  }
`

/**
 * Mutation to restock line items
 */
export const RESTOCK_LINE_ITEMS = gql`
  mutation restockLineItems(
    $orderId: ID!
    $restocks: [RestockedLineItemInput]!
  ) {
    restockLineItems(orderId: $orderId, restocks: $restocks) {
      orderId
    }
  }
`

/**
 * Mutation to export orders
 */
export const EXPORT_ORDERS = gql`
  mutation exportOrders($input: ExportOrdersInput!) {
    exportOrders(input: $input)
  }
`
