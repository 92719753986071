import gql from 'graphql-tag'

/**
 * Query to get a order for the specified orderId
 */
export const GET_ORDER = gql`
  query getOrder($orderId: ID!) {
    getOrder(orderId: $orderId) {
      exchangeRate {
        rate
        base
        target
      }
      canceledAt
      cancelReason
      charge {
        amount {
          amount
          currency
        }
        amountRefunded {
          amount
          currency
        }
        id
        isPaid
        isRefunded
        paymentMethod {
          card {
            country
            expMonth
            expYear
            last4
            network
          }
          type
        }
        risk {
          status
          reason
          type
          level
          message
          score
        }
        status
      }
      discount {
        id
        name
        type
        usage
      }
      createdAt
      customAttributes
      customer {
        analytics {
          averageOrderValue {
            amount
            currency
          }
          ordersCount
          totalSpent {
            amount
            currency
          }
        }
        billingAddress {
          address1
          address2
          addressCode
          city
          company
          country
          countryCode
          division
          divisionCode
          firstName
          lastName
          latitude
          longitude
          phone
        }
        createdAt
        customerId
        email
        firstName
        lastName
        lastOrder {
          orderConfirmedAt
          orderId
        }
        marketingOptInLevel
        shippingAddress {
          address1
          address2
          addressCode
          city
          company
          country
          countryCode
          division
          divisionCode
          firstName
          lastName
          latitude
          longitude
          phone
        }
        updatedAt
      }
      dispute {
        chargeId
        id
        status
        amount
        currency
        evidenceDetails {
          hasEvidence
          dueBy
          pastDue
          submissionCount
        }
        isChargeRefundable
      }
      finalSubTotalPrice {
        amount
        currency
      }
      fraudDetection {
        chargeId
        createdAt
        createdFraudAt
        fraudType
        id
        updatedAt
      }
      fulfillments {
        id
        lineItems {
          id
          quantity
        }
        shippingAddress {
          address1
          address2
          addressCode
          city
          company
          country
          countryCode
          division
          divisionCode
          firstName
          lastName
          latitude
          longitude
          phone
        }
        tracker {
          trackingNumber
          carrier
        }
      }
      fulfillmentStatus
      hasActivityComments
      lineItemQuantities
      lineItems {
        customAttributes
        finalPrice {
          amount
          currency
        }
        fulfillmentLineItems {
          lineItemId
          quantity
        }
        imageUrl
        lineItemId
        originalPrice {
          amount
          currency
        }
        productId
        productTitle
        quantity
        taxes {
          taxId
          title
          type
          percentage
          flatRate {
            amount
            currency
          }
          taxPrice {
            amount
            currency
          }
          collectionType
        }
        totalPrice {
          amount
          currency
        }
        variantId
        variantTitle
        variantType
      }
      netPayment {
        amount
        currency
      }
      note
      orderId
      originalSubTotalPrice {
        amount
        currency
      }
      refunds {
        amount {
          amount
          currency
        }
        chargeId
        id
        reason
        status
        lineItems {
          id
          quantity
        }
      }
      taxes {
        percentage
        taxId
        title
        taxPrice {
          amount
          currency
        }
        currency
        collectionType
      }
      requiresShipping
      shippingMethod {
        zone {
          name
        }
      }
      shippingPrice {
        amount
        currency
      }
      status
      paymentStatus
      taxPrice {
        amount
        currency
      }
      totalDiscountPrice {
        amount
        currency
      }
      totalPrice {
        amount
        currency
      }
      lineItemsRefund {
        amount
        currency
      }
      shippingRefund {
        amount
        currency
      }
      totalRefund {
        amount
        currency
      }
      fee {
        amount
        currency
      }
      chargebackFees {
        amount
        currency
      }
      totalChargebacks {
        amount
        currency
      }
      totalChargebackFee {
        amount
        currency
      }
      updatedAt
      usdPrices {
        netPayment {
          amount
          currency
        }
        totalPrice {
          amount
          currency
        }
        lineItemsRefund {
          amount
          currency
        }
        totalRefund {
          amount
          currency
        }
        fee {
          amount
          currency
        }
        totalDiscountPrice {
          amount
          currency
        }
        totalChargebackFee {
          amount
          currency
        }
        shippingRefund {
          amount
          currency
        }
        taxRefund {
          amount
          currency
        }
        totalChargebacks {
          amount
          currency
        }
        shippingPrice {
          amount
          currency
        }
        finalSubTotalPrice {
          amount
          currency
        }
        taxPrice {
          amount
          currency
        }
        originalSubTotalPrice {
          amount
          currency
        }
      }
    }
  }
`
export const GET_CUSTOMER_ORDERS_COUNT = gql`
  query getCustomerOrdersCount($customerId: ID!) {
    getCustomerOrdersCount(customerId: $customerId) {
      ordersCount
    }
  }
`
